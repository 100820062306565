import './App.css';
import Home from './Home';
import NavigationBar from './NavigationBar';
import { Routes ,Route,BrowserRouter} from 'react-router-dom';
function App() {
	return (
		<div className="App">
			<NavigationBar/>
			{/* <Routes>
				<Route path='/'>
					<Home/>
				</Route>
			</Routes> */}
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />}>
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
