import React from "react";
import NavigationBar from "./NavigationBar";
function Home() {
	return (
		<div>
			<div>
				HOME
			</div>
		</div>
		);
	}
export default Home;